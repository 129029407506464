<template>
	<div>
		<div class="d-flex align-items-center">
			<input ref="fileField" type="file" name="audio_file" class="hidden" hidden
				@change="fileChange"
			>
      <div class="d-flex align-items-center">
        <vue-record ref="audioRecorder" mode="press" @result="onResult" @stream="countTimeDown" />
        <div class="mx-50">
          <b-button @click="inputFile" class="btn-icon" variant="flat-success">
            <feather-icon icon="LinkIcon" />
          </b-button>
          <i>{{ timeLeft }}(s)</i>
        </div>
      </div>

		</div>
	</div>
</template>
<script>
import {VueRecordAudio } from '@codekraft-studio/vue-record'
export default {
	components: {
		'vue-record': VueRecordAudio
	},
	props: {
		file: { type: File, default: null },
		timeLimit: { type: Number, default: 30 },
		audioName: { type: String, default: 'audio_file' },
	},
	data() {
		return {
			blob: null,
			localFile: null,
			localFileName: null,
			recordAvailable: true,
			timeLeft: 0,
			countDownTimer: null,
      audioFile: null,

		}
	},
  computed: {
    audioUrl() {
      let url = this.audioFile ? URL.createObjectURL(this.audioFile) : null;
      return url;
    }
  },
  watch: {
    timeLeft(value) {
      if(value <= 0) {
        this.$refs.audioRecorder.stop();
        setTimeout(() => {
          this.recordAvailable = false;
        }, 100);
      }
    }
  },
	created() {
		this.checkIfRecorderAvailable();
		this.timeLeft = this.timeLimit;
	},
	methods: {
		onResult (data) {
			this.resetCountDownTimer();
			// let randomName = (new Date()).getTime().toString(36);
			let name = `audio_${this.audioName}.wav`;
			var blob = new Blob([data], { type: 'audio/wav' });
      blob.lastModifiedDate = new Date();
      blob.name = name;
      this.blob = blob;
      let file = new File([blob], name);
      this.audioFile = file;
      this.$emit('recorded', file);
    },
    downloadFile() {
    	saveAs(this.blob, this.blob.name);
    },
    checkIfRecorderAvailable() {
			if(!navigator.mediaDevices) {
				this.recordAvailable = false;
    		console.log('mic not found!');
				return;
			}
    	navigator.mediaDevices.getUserMedia({audio: true}).catch((error) => {
    		this.recordAvailable = false;
    		console.log('mic not found!');
    	})

    },
    countTimeDown() {

    	this.countDownTimer = setInterval(() => {
    		if(this.timeLeft <= 0 ){
    			clearInterval(this.countDownTimer);
	    		return;
	    	}
    		this.timeLeft--;
    	}, 1000);
    },
    resetCountDownTimer() {
    	this.timeLeft = this.timeLimit;
    	clearInterval(this.countDownTimer);
    },
		fileChange(event) {
			let path = event.target.value
			let fileName = path.match(/[^\\/]*$/)[0]
			this.localFileName = fileName
			this.localFile = this.$refs.fileField.files[0]
			this.$emit('setFile', this.localFile);
		},
		inputFile() {
			this.$refs.fileField.click();
		},
	},

}
</script>
